import React, { useState, useEffect, Suspense } from 'react';
import PageFooter from '../components/PageFooter'; 
import NoticiaFiltro from '../components/Noticias/NoticiaFiltro';
import NoticiaGrid from '../components/Noticias/NoticiaGrid';
import './Noticias.css'; 

const LazyPageBanner = React.lazy(() => import('../components/PageBanner'));

const Noticias = () => {
  const [noticias, setNoticias] = useState([]);
  const [filteredNoticias, setFilteredNoticias] = useState([]);
  const [visibleNoticias, setVisibleNoticias] = useState(4); // Muestra 4 noticias al inicio
  const [sortOrder, setSortOrder] = useState('reciente'); // Orden por defecto es 'Más recientes'

  useEffect(() => {
    const fetchNoticias = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/noticias?populate=*`);
      const data = await response.json();
      
      const noticiasArray = data.data.map((noticia) => ({
        id: noticia.id,
        ...noticia.attributes,
        previsualizacion: noticia.attributes.previsualizacion?.data?.[0]?.attributes?.url || '', // No se cambia
        fechaPublicacion: noticia.attributes.publishedAt // Usamos 'publishedAt' como fecha de referencia
      }));
      setNoticias(noticiasArray);
      setFilteredNoticias(noticiasArray);
    };

    fetchNoticias();
  }, []);

  const handleSearch = (searchTerm) => {
    const filtered = noticias.filter((noticia) =>
      noticia.titulo.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredNoticias(filtered);
  };

  const handleSort = (order) => {
    const sorted = [...filteredNoticias].sort((a, b) => {
      if (order === 'reciente') return new Date(b.fechaPublicacion) - new Date(a.fechaPublicacion);
      if (order === 'antigua') return new Date(a.fechaPublicacion) - new Date(b.fechaPublicacion);
      return 0;
    });
    setSortOrder(order);
    setFilteredNoticias(sorted);
  };

  const handleLoadMore = () => {
    setVisibleNoticias((prevVisible) => prevVisible + 4); // Cargar 4 noticias más
  };

  return (
    <>
      <div className="noticias-page">
        <div className="noticias-page-banner">
          <Suspense fallback={<div>Loading Banner...</div>}>
            <LazyPageBanner />
          </Suspense>
        </div>

        {/* Título antes del filtro, con la clase correcta */}
        <h2 className="noticias-page-h2">Buscar noticias</h2> 
        
        <div className="noticias-page-filtro">
          <NoticiaFiltro onSearch={handleSearch} onSort={handleSort} />
        </div>

        {/* Título antes del grid, con la clase correcta */}
        <h2 className="noticias-page-h2-resultados">Resultados</h2> 
        
        <div className="noticias-page-container">
          <NoticiaGrid noticias={filteredNoticias.slice(0, visibleNoticias)} />
        </div>

        {filteredNoticias.length > visibleNoticias && (
          <div className="noticias-page-ver-mas-container">
            <button className="noticias-page-ver-mas" onClick={handleLoadMore}>Ver más</button>
          </div>
        )}

        <div className="noticias-page-footer">
          <PageFooter />
        </div>
      </div>
    </>
  );
};

export default Noticias;
