import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import PageNavbar from './components/PageNavbar';
import Home from './pages/Home';
import Contacto from './pages/Contacto';
import Productos from './pages/Productos';
import OfertaLaboral from './pages/Ofertas';
import Servicios from './pages/Servicios';
import Nosotros from './pages/Nosotros';
import Noticias from './pages/Noticias';
import Concurso from './pages/Concurso';
import Modelo from './pages/Modelo';  // Importar la nueva página de modelo
import FullPageWrapper from './components/FullPageWrapper';
import './App.css';
import ErrorBoundary from './components/ErrorBoundary';

const NavbarSwitcher = () => {
  const location = useLocation();

  if (location.pathname === '/ofertalaboral' || location.pathname === '/test') {
    return null;
  } else {
    return <PageNavbar />;
  }
};

const Layout = ({ children }) => {
  const location = useLocation();
  
  const rootClassName = location.pathname === '/' ? 'home-page' : 'default-page';
  
  return <div className={rootClassName}>{children}</div>;
};

const App = () => {
  return (
    <Router>
      <Layout>
        <NavbarSwitcher />
        <ErrorBoundary>
          <Routes>
            <Route
              path="/"
              element={
                <FullPageWrapper>
                  <Home />
                </FullPageWrapper>
              }
            />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/productos" element={<Productos />} />
            <Route path="/ofertalaboral" element={<OfertaLaboral />} />
            <Route path="/servicios" element={<Servicios />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/noticias" element={<Noticias />} />
            <Route path="/modelo/:slug" element={<Modelo />} />
            <Route path="/concurso" element={<Concurso />} />
          </Routes>
        </ErrorBoundary>
      </Layout>
    </Router>
  );
};

export default App;
