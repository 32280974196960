import React from 'react';
import HomeContactoMapaDesktop from './HomeContactoMapaDesktop';
import HomeContactoCardDesktop from './HomeContactoCardDesktop';
import './HomeContactoSectionDesktop.css';

const HomeContactoSectionDesktop = ({
  selectedCountry,
  countries,
  onCountrySelect,
}) => {

  if (countries.length === 0) {
    return <div className="home-contacto-section-desktop-cargando">Cargando...</div>;
  }

  return (
    <div className="home-contacto-section-desktop-container">
      <div className="home-contacto-section-desktop-mapa">
        <HomeContactoMapaDesktop
          onCountrySelect={onCountrySelect}
          selectedCountry={selectedCountry}
          countries={countries}  // Pasamos los países al mapa
        />
      </div>
      <div className="home-contacto-section-desktop-card">
        <HomeContactoCardDesktop
          selectedCountry={selectedCountry}
          onCountryChange={onCountrySelect}
          countries={countries}  // Pasamos los países a la tarjeta
        />
      </div>
    </div>
  );
};

export default HomeContactoSectionDesktop;
