import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './PageNavbar.css'; // Mantén el CSS original
import logo from '../images/logo.webp';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Submenu from './Submenu'; // Importamos el Submenu

const PageNavbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const submenuTimeoutRef = useRef(null); // Ref para manejar el timeout

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.style.overflow = menuOpen ? 'auto' : 'hidden';
  };

  const handleMouseEnter = () => {
    if (submenuTimeoutRef.current) {
      clearTimeout(submenuTimeoutRef.current);
      submenuTimeoutRef.current = null;
    }
    setSubmenuOpen(true);
  };

  const handleMouseLeave = () => {
    submenuTimeoutRef.current = setTimeout(() => {
      setSubmenuOpen(false);
    }, 200);
  };

  return (
    <nav className="page-navbar">
      <div className="page-navbar-content">
        <div className="page-navbar-logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>

        {/* Links displayed on desktop */}
        <div className="page-navbar-links-desktop">
          <Link to="/">INICIO</Link>
          <div
            className="navbar-productos-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Link to="/productos" className="navbar-productos">PRODUCTOS</Link>
            {submenuOpen && <Submenu onClose={() => setSubmenuOpen(false)} />}
          </div>
          <Link to="/contacto">CONTACTO</Link>
        </div>

        {/* Hamburger icon for mobile */}
        <div className="page-navbar-hamburger" onClick={toggleMenu}>
          {menuOpen ? (
            <CloseIcon className="hamburger-icon" />
          ) : (
            <MenuIcon className="hamburger-icon" />
          )}
        </div>

        {/* Fullscreen menu for mobile */}
        <div className={`page-navbar-menu ${menuOpen ? 'open' : ''}`}>
          {/* Mobile Menu Links */}
          <div className="page-navbar-links-mobile">
            <Link to="/" onClick={toggleMenu}>INICIO</Link>
            <Link to="/productos" onClick={toggleMenu}>PRODUCTOS</Link>
            <Link to="/contacto" onClick={toggleMenu}>CONTACTO</Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default PageNavbar;
