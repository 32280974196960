import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import './FullPageWrapper.css';

const FullPageWrapper = ({ children }) => {
  return (
    <ReactFullpage
      licenseKey={'OPEN-SOURCE-GPLV3-LICENSE'}
      scrollingSpeed={1000}
      onLeave={(origin, destination) => {
        const navbar = document.querySelector('.home-navbar');

        if (navbar) {
          if (destination.index === 0) {
            navbar.style.backgroundColor = 'transparent';
            navbar.style.color = 'white';
            const links = navbar.querySelectorAll('a');
            links.forEach(link => {
              link.style.color = 'white';
            });
          } else {
            navbar.style.backgroundColor = 'white';
            navbar.style.color = 'black';
            const links = navbar.querySelectorAll('a');
            links.forEach(link => {
              link.style.color = 'black';
            });
          }
        }

        // Trigger map animation when entering the map section (index 2)
        if (destination.index === 1) {
          document.dispatchEvent(new Event('startAnimation'));
        }
      }}
      render={({ fullpageApi }) => (
        <ReactFullpage.Wrapper>
          {React.Children.map(children, child =>
            React.cloneElement(child, { fullpageApi })
          )}
        </ReactFullpage.Wrapper>
      )}
    />
  );
};

export default FullPageWrapper;
